import { useAuthToken } from '@monorepo/hooks';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export function PrivateRoute({ component: Component, ...rest }) {
  const { token } = useAuthToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component key={props.match.params.id || 'empty'} {...props} />
        ) : (
          <Redirect to={`/login?redirectURL=${window.location.href}`} />
        )
      }
    />
  );
}
export default PrivateRoute;
