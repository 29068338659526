import ApplicationList from './List';
// import OrderDetail from './Detail';
// import { Icon } from '~/assets/icons';
// import { USER_PERMISSIONS } from '@monorepo/config';

export const routerApplicationMobile = [
  {
    key: 'application',
    path: '/application',
    component: ApplicationList,
    exact: true,
  },
];

export default routerApplicationMobile;
