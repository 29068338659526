import { gql } from '@apollo/client';

export const NOTIFICATION_LIST = gql`
  query NotificationList($pagination: PaginationRequest!) {
    notification {
      pagination(pagination: $pagination) {
        notification {
          id
          title
          body
          redirectURL
          imageURL
          userID
          status
          createdAt
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const TOTAL_UNREAD_NOTIFICATIONS = gql`
  query TotalUnreadNotifications {
    notification {
      totalUnreadNotifications
    }
  }
`;
