import { ClockCircleOutlined } from "@ant-design/icons";
import { useMarkAsReadAll, useNotificationList, useUpdateStatusNotification, useWindowSize } from "@monorepo/hooks";
import { CustomPullToRefresh, PageHeader } from "@monorepo/shared-components";
import { Button } from "antd";
import { List } from "antd-mobile";
import { t } from "i18next";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./index.scss";

export const NotificationMobile = () => {
  const [params, setParams] = useState({
    pagination: { offset: 0, limit: 10 },
  });
  const [heightScrollAble, setHeightScrollAble] = useState(1);
  const { height } = useWindowSize();
  const { data, paginationData, fetchMore, refetch } = useNotificationList(params);
  const { handleUpdateStatus } = useUpdateStatusNotification();
  const { handleMarkAsReadAll } = useMarkAsReadAll();

  const fetchMoreData = async () => {
    await fetchMore({
      variables: {
        ...params,
        pagination: {
          offset: data?.length,
          limit: 10,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        return {
          notification: {
            pagination: {
              notification: [
                ...previousResult?.notification?.pagination?.notification,
                ...fetchMoreResult?.notification?.pagination?.notification,
              ],
              paginationData: fetchMoreResult?.notification?.pagination?.paginationData,
            },
          },
        };
      },
    });
  };

  useEffect(() => {
    const calcHeightScrollAble = height - 169;
    setHeightScrollAble(calcHeightScrollAble);
  }, [height]);

  return (
    <>
      <PageHeader pageTitle={t("common.notification")} />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="text" style={{ fontWeight: "bold" }} onClick={handleMarkAsReadAll}>
          {t("common.markAsReadAll")}
        </Button>
      </div>
      <CustomPullToRefresh onRefresh={refetch}>
        <InfiniteScroll
          next={fetchMoreData}
          dataLength={data.length}
          hasMore={paginationData?.total === data?.length ? false : true}
          height={heightScrollAble}
        >
          <List>
            {data?.map((item, index) => (
              <List.Item
                key={item?.id}
                description={
                  <div className="description-notification">
                    {item.body}
                    <div className="time">
                      <ClockCircleOutlined />
                      {item.createdAt}
                    </div>
                  </div>
                }
                style={{ backgroundColor: item?.isRead ? " #FFFFFF" : "#E7F0FF" }}
                onClick={() => handleUpdateStatus([item])}
                arrow={false}
              >
                {item?.title}
              </List.Item>
            ))}
          </List>
        </InfiniteScroll>
      </CustomPullToRefresh>
    </>
  );
};

export default NotificationMobile;
