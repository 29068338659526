import { REGEX, USER_PERMISSIONS } from '@monorepo/config';
import { useConfirmDeliver, useGetOrders, useGetShipper, useUpdateShipper } from '@monorepo/hooks';
import { CustomModal } from '@monorepo/shared-components';
import { debounce, getQuery, notify, removeWhiteSpace } from '@monorepo/utils';
import { Button, Empty, Form, InputNumber, Select, Spin, Tag } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { has } from 'lodash';

const TAG_MIN_WIDTH = '90px';

export const CustomModalSelectShipper = ({
  orderID,
  labelButton,
  refetch,
  typeButton = 'primary',
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [switchClose, setSwitchClose] = useState(false);
  const { handleConfirmDeliver, loading: loadingConfirmDeliver } = useConfirmDeliver();
  const { handleUpdateShipper, loading: loadingUpdateShipper } = useUpdateShipper();

  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    shipperSearch: null,
  });

  const getParams = {
    filters: {
      query: params.shipperSearch || null,
      permission: USER_PERMISSIONS.ORDER_SHIPPER_DELIVER,
    },
    pagination: {
      offset: params.offset,
      limit: params.limit,
    },
  };

  const GET_QUERY = getQuery();

  const { shippers = [], loading: loadingGetShipper } = useGetShipper(getParams);

  const changeParamsOnSearch = ([text]) => {
    const searchText = removeWhiteSpace(text);

    const inputText = searchText?.length > 0 ? searchText : undefined;
    setParams((prev) => ({ ...prev, shipperSearch: inputText }));
  };

  const handleDebouncedSearchShipper = debounce(changeParamsOnSearch, 500);

  const handleCancel = () => {
    setSwitchClose(false);
    form.resetFields();
  };

  const onOverlayClick = (e) => e.stopPropagation();

  const handleOke = async () => {
    const formValues = { ...form.getFieldsValue() };

    await form.validateFields();
    if (has(GET_QUERY, 'shippingStatus') && GET_QUERY.shippingStatus) {
      try {
        await handleUpdateShipper({
          orderIDs: [orderID],
          shipperID: formValues.shipperID,
        }).then(() => refetch());
        notify.success({ message: t('common.changeSuccess'), placement: 'bottom' });
        setSwitchClose(!switchClose);
      } catch (error) {
        notify.error({ description: error?.message, placement: 'bottom' });
      }
    } else {
      try {
        await handleConfirmDeliver({
          orderIDs: [orderID],
          shipperID: formValues.shipperID,
        }).then(() => refetch());
        notify.success({ message: t('common.changeSuccess'), placement: 'bottom' });
        setSwitchClose(!switchClose);
      } catch (error) {
        notify.error({ description: error?.message, placement: 'bottom' });
      }
    }
  };

  const renderTag = (status, count) => {
    const COLOR_BY_STATUS = {
      WAIT_TO_DELIVER: 'orange',
      IN_DELIVER: 'red',
    };
    const LABEL_BY_STATUS = {
      WAIT_TO_DELIVER: t('order.selectingShipper.orderWaitAssigned'),
      IN_DELIVER: t('order.selectingShipper.orderAssigned'),
    };
    return (
      <Tag
        color={`${COLOR_BY_STATUS[status]}`}
        style={{ minWidth: TAG_MIN_WIDTH, textAlign: 'center' }}
      >
        <em>{`${LABEL_BY_STATUS[status]} ${count}`}</em>
      </Tag>
    );
  };

  const renderTagList = (data) => {
    return data?.length > 0 ? (
      data.map(({ status, count }) => renderTag(status, count))
    ) : (
      <Tag color="green" style={{ minWidth: TAG_MIN_WIDTH, textAlign: 'center' }}>
        <em>{t('order.selectingShipper.orderNotAssigned')}</em>
      </Tag>
    );
  };

  const convertData = (data) => {
    return data?.map((record) => ({
      value: record?.value,
      name: record?.name,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            whiteSpace: 'break-spaces',
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              wordBreak: 'break-word',
            }}
          >
            {record?.label}
          </span>

          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            {renderTagList(record?.inDeliverOrders)}
          </span>
        </div>
      ),
    }));
  };

  return (
    <CustomModal
      closable={false}
      centered={true}
      footer={false}
      isBlockCloseOnOke
      switchClose={switchClose}
      onCancel={handleCancel}
      onOke={handleOke}
      customComponent={<Button type={typeButton}>{labelButton}</Button>}
      buttonLoading={loadingConfirmDeliver || loadingUpdateShipper}
    >
      <Form form={form} name="create-cart-from" layout="vertical">
        <Form.Item
          label={<b>{t('order.selectingShipper.shipperLabel')}</b>}
          name="shipperID"
          rules={[
            {
              required: true,
              message: t('order.selectingShipper.shipperValidateMessage'),
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder={t('order.selectingShipper.shipperPlaceholder')}
            filterOption={false}
            optionLabelProp="name"
            notFoundContent={loadingGetShipper ? <Spin size="default" /> : <Empty />}
            onSearch={handleDebouncedSearchShipper}
            options={convertData(shippers)}
            onClick={onOverlayClick}
          ></Select>
        </Form.Item>
        {/* <Form.Item label={<b>{t('order.selectingShipper.receivableLabel')}</b>} name="receivable">
          <InputNumber
            formatter={(value) => value.replace(REGEX.CURRENCY, '.')}
            parser={(value) => value.replace(REGEX.CURRENCY_PARSER, '')}
            controls={false}
          ></InputNumber>
        </Form.Item> */}
      </Form>
    </CustomModal>
  );
};

export default CustomModalSelectShipper;
