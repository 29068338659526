import { useMutation, useQuery } from "@apollo/client";
import {
  convertGetAllOrder,
  convertGetCountOrderWithFilter,
  convertGetCreatedBy,
  convertGetWarehouse,
  convertPagination,
} from "./converter";

import {
  APPROVE_STOCK,
  CANCEL_ORDER,
  COMPLETED_ORDER,
  COMPLETE_ASSEMBLY,
  CONFIRM_CUSTOMER_PICKED,
  CONFIRM_DELIVER,
  CONFIRM_DELIVER_SHIPPING_PROVIDER,
  CONFIRM_EXPORT_ORDER,
  CONFIRM_PAYMENT,
  CONFIRM_PICKED_FOR_SHIPPING,
  CREATE_ORDER,
  DENY_STOCK,
  EXPORT_ORDER,
  GET_COUNT_ORDER_WITH_FILTERS,
  GET_CREATED_BY,
  GET_CREATED_BY_WITH_IDS,
  GET_ME_QUERY,
  GET_ORDERS_QUERY,
  GET_ORDER_DETAIL_QUERY,
  GET_SUMMARY_TABS,
  GET_WAREHOUSES,
  GET_WAREHOUSES_WITH_IDS,
  PACKAGE_ORDER,
  UPDATE_SHIPPER,
  UPDATE_STATUS,
  UPLOAD_AND_UPDATE_FILES,
  WAITING_FOR_ENOUGH_STOCK,
} from "@monorepo/data-access";

import { omit } from "lodash";

import { USER_PERMISSIONS } from "@monorepo/config";
import { useGetUserPermissions } from "@monorepo/hooks";
import { hasPermission } from "@monorepo/utils";
import imageCompression from "browser-image-compression";

export const useGetOrders = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_ORDERS_QUERY, {
    variables: omit(params, "skipQuery"),
  });

  return {
    loading,
    data: convertGetAllOrder(data?.order?.pagination?.orders) || [],
    paginationData: data?.order?.pagination?.paginationData,
    refetch,
    fetchMore,
  };
};

export const useGetWarehouses = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_WAREHOUSES);
  return {
    loading,
    data: convertGetWarehouse(data?.warehouse?.list?.warehouses),
    refetch,
    fetchMore,
  };
};

export const useGetCreatedBy = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_CREATED_BY, {
    variables: params,
  });
  return {
    loading,
    data: convertGetCreatedBy(data?.user?.pagination?.users) || [],
    paginationData: convertPagination(data?.user?.pagination?.paginationData),
    refetch,
    fetchMore,
  };
};

export const useGetSummaryTabs = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SUMMARY_TABS);
  return {
    loading,
    data: data?.order?.totalByStatus,
    refetch,
    fetchMore,
  };
};

export const useCreateOrder = () => {
  const [createOrder, { loading, error }] = useMutation(CREATE_ORDER);
  const handleCreateOrder = async ({ id }) => {
    return await createOrder({
      variables: { cartID: id },
      refetchQueries: [GET_ME_QUERY],
    });
  };
  return {
    handleCreateOrder,
    loading,
    error,
  };
};

export const useGetOrderDetail = ({ id }) => {
  const { loading, data, refetch } = useQuery(GET_ORDER_DETAIL_QUERY, {
    variables: {
      id,
    },
  });
  return {
    loading,
    order: data?.order?.get,
    refetch,
  };
};

export const useUpdateStatus = () => {
  const [updateStatus, { loading, error }] = useMutation(UPDATE_STATUS);
  const handleUpdateStatus = async (params) => {
    return await updateStatus({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleUpdateStatus,
    loading,
    error,
  };
};

export const useApproveStock = () => {
  const [approveStock, { loading, error }] = useMutation(APPROVE_STOCK);
  const handleApproveStock = async (params) => {
    return await approveStock({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY],
    });
  };
  return {
    handleApproveStock,
    loading,
    error,
  };
};

export const useCancelOrder = () => {
  const [cancelOrder, { loading, error }] = useMutation(CANCEL_ORDER);
  const handleCancelOrder = async (params) => {
    return await cancelOrder({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY],
    });
  };
  return {
    handleCancelOrder,
    loading,
    error,
  };
};

export const useConfirmPayment = () => {
  const [confirmPayment, { loading, error }] = useMutation(CONFIRM_PAYMENT);
  const handleConfirmPayment = async (params) => {
    return await confirmPayment({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY],
    });
  };
  return {
    handleConfirmPayment,
    loading,
    error,
  };
};

export const useConfirmExportOrder = () => {
  const [confirmExportOrder, { loading, error }] = useMutation(CONFIRM_EXPORT_ORDER);
  const handleConfirmExportOrder = async (params) => {
    return await confirmExportOrder({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleConfirmExportOrder,
    loading,
    error,
  };
};

export const useCompletedOrder = () => {
  const [completedOrder, { loading, error }] = useMutation(COMPLETED_ORDER);
  // const { handleUpload, loading: loadingUpload } = useUploadDocument();
  const handleCompletedOrder = async (params) => {
    return await completedOrder({
      variables: { ...params },
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleCompletedOrder,
    loading: loading,
    error,
  };
};

export const useConfirmDeliver = () => {
  const [confirmDeliver, { loading, error }] = useMutation(CONFIRM_DELIVER);
  const handleConfirmDeliver = async (params) => {
    return await confirmDeliver({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleConfirmDeliver,
    loading,
    error,
  };
};

export const useWaitingStock = () => {
  const [waitingStock, { loading, error }] = useMutation(WAITING_FOR_ENOUGH_STOCK);
  const handleWaitingStock = async (params) => {
    return await waitingStock({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY],
    });
  };
  return {
    handleWaitingStock,
    loading,
    error,
  };
};

export const useDenyStock = () => {
  const [denyStock, { loading, error }] = useMutation(DENY_STOCK);
  const handleDenyStock = async (params) => {
    return await denyStock({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY],
    });
  };
  return {
    handleDenyStock,
    loading,
    error,
  };
};

export const useCompleteAssembly = () => {
  const [completeAssembly, { loading, error }] = useMutation(COMPLETE_ASSEMBLY);
  const handleCompleteAssembly = async (params) => {
    return await completeAssembly({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleCompleteAssembly,
    loading,
    error,
  };
};

export const useGetWarehousesWithIds = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_WAREHOUSES_WITH_IDS, {
    variables: params,
    skip: params?.request?.ids === undefined,
  });

  return {
    loading,
    data: convertGetWarehouse(data?.warehouse?.list?.warehouses),
    refetch,
    fetchMore,
  };
};

export const useGetCreatedByWithIds = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_CREATED_BY_WITH_IDS, {
    variables: { filters: { ...params } },
    skip: params?.ids === undefined,
  });

  return {
    loading,
    data: convertGetCreatedBy(data?.user?.pagination?.users) || [],
    refetch,
    fetchMore,
  };
};

export const useOrderPermissions = () => {
  const { data: permissions = [] } = useGetUserPermissions();
  return {
    canCreateOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CREATE]),
    canViewOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_VIEW]),
    canApproveStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_APPROVE_STOCK]),
    canWaitStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_WAIT_STOCK]),
    canDenyStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_DENY_STOCK]),
    canConfirmPayment: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CONFIRM_PAYMENT]),
    canExportStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_EXPORT_STOCK]),
    canCompleteAssembly: hasPermission(permissions, [USER_PERMISSIONS.ORDER_COMPLETE_ASSEMBLY]),
    canAssignShipper: hasPermission(permissions, [USER_PERMISSIONS.ORDER_ASSIGN_SHIPPER]),
    canCompleteOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_COMPLETE]),
    canRecreateCart: hasPermission(permissions, [USER_PERMISSIONS.ORDER_RECREATE_CART]),
    canCancelOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CANCEL]),
  };
};

export const useExportOrder = () => {
  const [exportOrder, { loading, error }] = useMutation(EXPORT_ORDER);

  const handleExportOrder = async ({ orderID, fileType }) => {
    return await exportOrder({
      variables: {
        orderID,
        fileType,
      },
      refetchQueries: [GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleExportOrder,
    loading,
    error,
  };
};

export const useUpdateShipper = () => {
  const [updateShipper, { loading, error }] = useMutation(UPDATE_SHIPPER);

  const handleUpdateShipper = async ({ orderIDs, shipperID }) => {
    return await updateShipper({
      variables: {
        orderIDs,
        shipperID,
      },
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleUpdateShipper,
    loading,
    error,
  };
};

export const useConfirmPickedForShipping = () => {
  const [confirmPickedForShipping, { loading, error }] = useMutation(CONFIRM_PICKED_FOR_SHIPPING);

  const handleConfirmPickedForShipping = async ({ orderIDs }) => {
    return await confirmPickedForShipping({
      variables: {
        orderIDs,
      },
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };
  return {
    handleConfirmPickedForShipping,
    loading,
    error,
  };
};

export const useShipperAppPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    canShipperDeliver: hasPermission(permissions, [USER_PERMISSIONS.ORDER_SHIPPER_DELIVER]),
    canAssignShipper: hasPermission(permissions, [USER_PERMISSIONS.ORDER_ASSIGN_SHIPPER]),
    loading,
  };
};

export const useGetCountOrderWithFilters = () => {
  const { data, loading, error, refetch } = useQuery(GET_COUNT_ORDER_WITH_FILTERS);
  return { data: convertGetCountOrderWithFilter(data), loading, error, refetch };
};

export const useConfirmDeliverShippingProvider = () => {
  const [confirmDeliverShippingProvider, { loading, error }] = useMutation(CONFIRM_DELIVER_SHIPPING_PROVIDER);

  const handleConfirmDeliverShippingProvider = async ({ orderIDs, imageURLs }) => {
    return await confirmDeliverShippingProvider({
      variables: {
        orderIDs,
        imageURLs,
      },
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };

  return {
    handleConfirmDeliverShippingProvider,
    loading,
    error,
  };
};

export const usePackageOrder = () => {
  const [packageOrder, { loading, error }] = useMutation(PACKAGE_ORDER);

  const handlePackage = async ({ orderIDs }) => {
    return await packageOrder({
      variables: {
        orderIDs,
      },
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };

  return {
    handlePackage,
    loading,
    error,
  };
};

async function handleCompressionImage(imageFile) {
  const options = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {}
}

export const useUploadAndUpdateFiles = () => {
  const [uploadAndUpdateFiles, { loading, error }] = useMutation(UPLOAD_AND_UPDATE_FILES);

  const handleCompressImages = async (images) => {
    return await Promise.all(images?.map((item) => handleCompressionImage(item.originFileObj)));
  };

  const handleUploadAndUpdateFiles = async (params) => {
    const compressionImages = await Promise.all(
      params?.newFiles?.map((item) => handleCompressionImage(item.originFileObj))
    );

    return await uploadAndUpdateFiles({
      variables: {
        ...params,

        newFiles: compressionImages,
      },
    });
  };

  return {
    handleUploadAndUpdateFiles,
    handleCompressImages,
    loading,
    error,
  };
};

export const useConfirmCustomerPicked = () => {
  const [confirmCustomerPicked, { loading, error }] = useMutation(CONFIRM_CUSTOMER_PICKED);

  const handleConfirmCustomerPicked = async ({ orderIDs, imageURLs }) => {
    return await confirmCustomerPicked({
      variables: {
        orderIDs,
        imageURLs,
      },
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, GET_COUNT_ORDER_WITH_FILTERS],
    });
  };

  return {
    handleConfirmCustomerPicked,
    loading,
    error,
  };
};
