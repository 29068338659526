import { ACCEPT_IMG_TYPES, PAYMENT_METHOD } from "@monorepo/config";
import { useCompletedOrder } from "@monorepo/hooks";
import { CustomModal, UploadDocument } from "@monorepo/shared-components";
import { formatCurrency, notify } from "@monorepo/utils";
import { Button, Checkbox, Form, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

export function CustomModalDeliverySuccess({ orderID, refetch, codAmount, receiverName, number, paymentMethod }) {
  const { t } = useTranslation();
  const [fileUrls, setFileUrls] = useState([]);
  const { handleCompletedOrder, loading: loadingCompleteOrder } = useCompletedOrder();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleUploadSuccess = async (urls) => {
    setLoading(true);
    try {
      setFileUrls(urls);
    } catch (err) {
      notify.error({ message: err });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await form.validateFields().then(async (values) => {
        await handleCompletedOrder({ orderID: orderID, images: fileUrls.map((item) => item?.originFileObj) }).then(
          () => {
            setVisible(false);
            notify.success({
              message: t("order.deliverySuccess.successfulDelivery"),
            });
            refetch();
            setFileUrls([]);
            form.resetFields();
          }
        );
      });
    } catch (e) {
      notify.error({
        message: t("order.deliverySuccess.errorDelivery"),
        description: e?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpen = () => {
    setVisible(true);
  };
  return (
    <CustomModal
      centered={true}
      footer={false}
      isBlockCloseOnOke
      onOke={handleConfirm}
      customComponent={
        <Button type="primary" loading={loading}>
          {t("order.orderList.linkShippingSuccess")}
        </Button>
      }
      buttonLoading={loading}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOpen={handleOpen}
      selfVisibleControlled={!visible}
      switchClose={!visible}
    >
      <Form form={form} initialValues={{ checkbox: !(paymentMethod === PAYMENT_METHOD.COD) }}>
        <div className="upload-image">
          <center className="title-modal">{t("bank_transfer.delivery_confirmation")}</center>
          <div className="total-fee">
            <span>{t("bank_transfer.please_tick")}</span>
          </div>
          <div className="form-item-checkbox">
            <Form.Item
              name="checkbox"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error(t("bank_transfer.warning_confirm_order"))),
                },
              ]}
            >
              <Checkbox disabled={!(paymentMethod === PAYMENT_METHOD.COD)}>
                <Typography.Text>
                  {t("bank_transfer.order")}
                  <span className="text-blue">{number}</span>
                  {t("bank_transfer.value")}
                  <span className="text-blue">{formatCurrency(codAmount)}</span>
                  {t("bank_transfer.by_seller")}
                  <span className="text-blue">{receiverName}</span>
                  {t("bank_transfer.success_payment")}
                </Typography.Text>
              </Checkbox>
            </Form.Item>
          </div>
          <p className="sub-title">{t("bank_transfer.attack_imgae")}</p>
          <UploadDocument onUploadSuccess={handleUploadSuccess} accept={ACCEPT_IMG_TYPES.join(",")} />
          <p className="files-support">({t("order.deliverySuccess.supportedFiles")})</p>
        </div>
      </Form>
    </CustomModal>
  );
}
export default CustomModalDeliverySuccess;
