import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation create($cartID: ID!) {
    order {
      create(cartID: $cartID) {
        id
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation updateStatus($orderIDs: [ID!]!, $newStatus: OrderStatus!) {
    order {
      updateStatus(orderIDs: $orderIDs, newStatus: $newStatus)
    }
  }
`;

export const APPROVE_STOCK = gql`
  mutation approveStock($orderIDs: [ID!]!) {
    order {
      approveStock(orderIDs: $orderIDs)
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($orderIDs: [ID!]!, $reason: String) {
    order {
      cancel(orderIDs: $orderIDs, reason: $reason)
    }
  }
`;

export const CONFIRM_PAYMENT = gql`
  mutation confirmPayment($orderIDs: [ID!]!) {
    order {
      confirmPayment(orderIDs: $orderIDs)
    }
  }
`;

export const CONFIRM_EXPORT_ORDER = gql`
  mutation confirmExportOrder($orderIDs: [ID!]!) {
    order {
      export(orderIDs: $orderIDs)
    }
  }
`;

export const COMPLETED_ORDER = gql`
  mutation completedOrder($orderID: ID!, $images: [Upload!]) {
    order {
      complete(orderID: $orderID, images: $images)
    }
  }
`;

export const CONFIRM_DELIVER = gql`
  mutation confirmDeliver($orderIDs: [ID!]!, $shipperID: ID!) {
    order {
      deliver(orderIDs: $orderIDs, shipperID: $shipperID)
    }
  }
`;

export const WAITING_FOR_ENOUGH_STOCK = gql`
  mutation waitingStock($orderIDs: [ID!]!) {
    order {
      waitStock(orderIDs: $orderIDs)
    }
  }
`;

export const DENY_STOCK = gql`
  mutation denyStock($orderIDs: [ID!]!) {
    order {
      denyStock(orderIDs: $orderIDs)
    }
  }
`;

export const EXPORT_ORDER = gql`
  mutation exportOrder($orderID: ID!, $fileType: ExportFileType!) {
    order {
      exportFile(orderID: $orderID, fileType: $fileType) {
        url
      }
    }
  }
`;

export const COMPLETE_ASSEMBLY = gql`
  mutation assembly($orderIDs: [ID!]!) {
    order {
      assembly(orderIDs: $orderIDs)
    }
  }
`;

export const UPDATE_SHIPPER = gql`
  mutation updateShipper($orderIDs: [ID!], $shipperID: ID!) {
    order {
      updateShipper(orderIDs: $orderIDs, shipperID: $shipperID)
    }
  }
`;

export const CONFIRM_PICKED_FOR_SHIPPING = gql`
  mutation confirmPickedForShipping($orderIDs: [ID!]) {
    order {
      confirmPickedForShipping(orderIDs: $orderIDs)
    }
  }
`;

export const CONFIRM_DELIVER_SHIPPING_PROVIDER = gql`
  mutation confirmDeliverShippingProvider($orderIDs: [ID!]!, $imageURLs: [String!]) {
    order {
      confirmDeliverShippingProvider(orderIDs: $orderIDs, imageURLs: $imageURLs)
    }
  }
`;

export const PACKAGE_ORDER = gql`
  mutation package($orderIDs: [ID!]!) {
    order {
      package(orderIDs: $orderIDs)
    }
  }
`;

export const UPLOAD_AND_UPDATE_FILES = gql`
  mutation uploadAndUpdateFiles($orderID: ID!, $newFiles: [Upload!], $oldFileURLs: [String!]) {
    order {
      uploadAndUpdateFiles(orderID: $orderID, newFiles: $newFiles, oldFileURLs: $oldFileURLs)
    }
  }
`;

export const CONFIRM_CUSTOMER_PICKED = gql`
  mutation confirmCustomerPicked($orderIDs: [ID!]!, $imageURLs: [String!]) {
    order {
      confirmCustomerPicked(orderIDs: $orderIDs, imageURLs: $imageURLs)
    }
  }
`;
