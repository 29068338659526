import Account from './Account';
import InfoUser from './InfoUser';
// import OrderDetail from './Detail';
// import { Icon } from '~/assets/icons';
// import { USER_PERMISSIONS } from '@monorepo/config';

export const routerProfileMobile = [
  {
    key: 'user',
    path: '/user',
    component: Account,
    exact: true,
  },
  {
    key: 'user/:id',
    path: '/user/:id',
    component: InfoUser,
    exact: true,
  },
];

export default routerProfileMobile;
