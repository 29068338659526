import { gql } from '@apollo/client';

export const GET_ME_QUERY = gql`
  {
    user {
      me {
        id
        fullname
        email
        roles {
          id
          code
          name
        }
        wallets {
          id
          balance
          type
        }
        wallet {
          id
          balance
          type
        }
        totalCart
        avatarURL
        department {
          name
        }
        telephone
      }
    }
  }
`;

export const GET_INFO_PLATFORM = gql`
  query GetInfoPlatform {
    platform {
      get {
        id
        name
        code
        logoURL
        bannerURL
      }
    }
  }
`;
