import { useMutation, useQuery } from '@apollo/client';
import { LOGIN_MUTATION, GET_ME_QUERY, GET_INFO_PLATFORM } from '@monorepo/data-access';
import { useAuthToken } from '@monorepo/hooks';

export const useLogin = () => {
  const [mutateLogin, { loading }] = useMutation(LOGIN_MUTATION);

  const handleLogin = async (loginRequest) => {
    const fetchResult = await mutateLogin({ variables: { loginRequest } });
    const { accessToken } = fetchResult?.data?.auth?.login;
    return { accessToken };
  };

  return {
    handleLogin,
    loading,
  };
};

export function useGetMe() {
  const { token } = useAuthToken();
  // skip if no access token available
  const { loading, data, refetch } = useQuery(GET_ME_QUERY, {
    skip: !token,
  });
  return {
    loading,
    me: data?.user?.me,
    refetch,
  };
}
export const useGetInfoPlatform = () => {
  const { data } = useQuery(GET_INFO_PLATFORM);
  const platformGet = data?.platform?.get;

  return {
    platformURL: platformGet?.logoURL,
    platformBgURL: platformGet?.bannerURL,
    platformName: platformGet?.name,
    platformCode: platformGet?.code,
  };
};
