import { gql } from '@apollo/client';

export const UPDATE_STATUS_NOTIFICATION = gql`
  mutation UpdateStatus($ids: [ID!]) {
    notification {
      updateStatus(ids: $ids)
    }
  }
`;

export const MASK_AS_READ_ALL = gql`
  mutation MarkAsReadAll {
    notification {
      markAsReadAll
    }
  }
`;
