import { useEffect } from "react";
import { getAppRoutes } from "../../routes";

import { useGetInfoPlatform, useUpdateUserNotification, useWebViewExchange } from "@monorepo/hooks";
import { LayoutMobile } from "@monorepo/shared-components";

const App = () => {
  const { getToken, isFlutterInAppWebViewReady } = useWebViewExchange();
  const { handleUpdateUserNotificationToken } = useUpdateUserNotification();
  const { platformName } = useGetInfoPlatform();

  useEffect(() => {
    document.title = platformName || "";
  }, [platformName]);

  useEffect(() => {
    if (isFlutterInAppWebViewReady) {
      getToken().then((token) => {
        console.log("token " + token);
        if (token) {
          handleUpdateUserNotificationToken(token);
        }
      });
    }
  }, [isFlutterInAppWebViewReady]);
  return <LayoutMobile getAppRoutes={getAppRoutes} />;
};

export default App;
