import React from 'react';

const PhoneComponent = ({ phoneNumber, ...rest }) => {
  return (
    <a href={`tel:${phoneNumber}`} {...rest}>
      {phoneNumber}
    </a>
  );
};

export default PhoneComponent;
