import { t } from 'i18next';
import React from 'react';
import { PullToRefresh } from 'antd-mobile';

export const CustomPullToRefresh = ({ children, ...rest }) => {
  return (
    <PullToRefresh
      pullingText={t('common.pullingText')}
      canReleaseText={t('common.canReleaseText')}
      refreshingText={t('common.refreshingText')}
      completeText={t('common.completeText')}
      {...rest}
    >
      {children}
    </PullToRefresh>
  );
};

export default CustomPullToRefresh;
