import { PageHeader } from '@monorepo/shared-components';
import { Avatar, List } from 'antd';
import React from 'react';
import './index.scss';
import { UserOutlined, LockOutlined, LogoutOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useLogout, useGetMe } from '@monorepo/hooks';
import { useTranslation } from 'react-i18next';

const Account = () => {
  const history = useHistory();
  const { logout } = useLogout();
  const { me } = useGetMe();
  const { t } = useTranslation();
  const renderRoleName = me?.roles?.map((item) => item.name).join(', ');

  const menu = [
    {
      icon: <UserOutlined style={{ fontSize: '24px' }} />,
      title: t('profileMobile.infoUser'),
      hasArrows: true,
      handleClick: () => history.push(`/user/${me?.id}`),
    },
    // {
    //   icon: <LockOutlined style={{ fontSize: '24px' }} />,
    //   title: t('profileMobile.changePassword'),
    //   hasArrows: true,
    //   handleClick: () => history.push('change-password'),
    // },
    {
      icon: <LogoutOutlined style={{ fontSize: '24px' }} />,
      title: t('profileMobile.logout'),
      hasArrows: false,
      handleClick: () => logout(),
    },
  ];
  return (
    <>
      <PageHeader pageTitle="Tài khoản" />
      <div className="user-description">
        <Avatar size={110} src={me?.avatarURL} alt="Dog" />
        <span>{me?.fullname}</span>
        <span>{renderRoleName}</span>
      </div>
      <div className="menu-profile">
        <List
          itemLayout="horizontal"
          dataSource={menu}
          renderItem={(item) => (
            <List.Item onClick={item.handleClick}>
              {item?.icon}
              <span>{item?.title}</span>
              {item?.hasArrows && (
                <ArrowRightOutlined style={{ marginLeft: 'auto', fontSize: '24px' }} />
              )}
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default Account;
