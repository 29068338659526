/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useMutation, useQuery } from "@apollo/client";
import {
  MASK_AS_READ_ALL,
  NOTIFICATION_LIST,
  TOTAL_UNREAD_NOTIFICATIONS,
  UPDATE_STATUS_NOTIFICATION,
} from "@monorepo/data-access";
import { notify } from "@monorepo/utils";
import { convertNotificationList } from "./converter";

export const useNotificationList = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(NOTIFICATION_LIST, {
    variables: params,
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    data: convertNotificationList(data?.notification?.pagination?.notification) || [],
    paginationData: data?.notification?.pagination?.paginationData,
    refetch,
    fetchMore,
  };
};

export const useTotalUnreadNotifications = () => {
  const { loading, data, refetch, fetchMore } = useQuery(TOTAL_UNREAD_NOTIFICATIONS, {
    pollInterval: 60000,
  });
  return {
    loading,
    data: data?.notification?.totalUnreadNotifications || 0,
    refetch,
    fetchMore,
  };
};

export const useUpdateStatusNotification = () => {
  const [updateStatus, { loading, error }] = useMutation(UPDATE_STATUS_NOTIFICATION);
  const handleUpdateStatus = async (ids) => {
    if (ids[0].status === "UNREAD") {
      return await updateStatus({
        variables: { ids: [ids[0]?.id] },
        refetchQueries: [TOTAL_UNREAD_NOTIFICATIONS],
        onError: (error) => notify.error({ message: error.message }),
        update(cache, { data }) {
          const { notification } = cache.readQuery({
            query: NOTIFICATION_LIST,
            variables: {
              pagination: {
                offset: 0,
                limit: 10,
              },
            },
          });

          cache.writeQuery({
            query: NOTIFICATION_LIST,
            variables: {
              pagination: {
                offset: 0,
                limit: 10,
              },
            },
            data: {
              notification: {
                pagination: {
                  notification: notification?.pagination?.notification?.map((item) => {
                    if (item?.id === ids[0]?.id) {
                      return { ...item, status: "READ" };
                    }
                    return item;
                  }),
                  paginationData: notification?.pagination?.paginationData,
                },
              },
            },
          });
        },
      });
    }
    return;
  };

  return {
    handleUpdateStatus,
    loading,
    error,
  };
};

export const useMarkAsReadAll = () => {
  const [markAsReadAll, { loading, error }] = useMutation(MASK_AS_READ_ALL);

  const handleMarkAsReadAll = async () => {
    return await markAsReadAll({
      onError: (error) => notify.error({ message: error.message }),
      refetchQueries: [TOTAL_UNREAD_NOTIFICATIONS],
      update(cache, { data }) {
        const { notification } = cache.readQuery({
          query: NOTIFICATION_LIST,
          variables: {
            pagination: {
              offset: 0,
              limit: 10,
            },
          },
        });

        cache.writeQuery({
          query: NOTIFICATION_LIST,
          variables: {
            pagination: {
              offset: 0,
              limit: 10,
            },
          },
          data: {
            notification: {
              pagination: {
                notification: notification?.pagination?.notification?.map((item) => {
                  return { ...item, status: "READ" };
                }),
                paginationData: notification?.pagination?.paginationData,
              },
            },
          },
        });
      },
    });
  };

  return {
    handleMarkAsReadAll,
    loading,
    error,
  };
};
