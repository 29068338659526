import { useState, useEffect } from 'react';

const KEY_NOTIFICATION = 'GET_NOTIFICATION_TOKEN';
const EXCHANGE_CHANNEL = 'exchange';

// Exchange data with webview
export const useWebViewExchange = () => {
  const [isFlutterInAppWebViewReady, setIsFlutterInAppWebViewReady] = useState(false);
  function getToken() {
    return window.flutter_inappwebview.callHandler(EXCHANGE_CHANNEL, KEY_NOTIFICATION).
      then(res => {
        return res.token;
      });
  }

  useEffect(() => {
    const setFlutterWebViewReady = event => {
      setIsFlutterInAppWebViewReady(true);
    };
    window.addEventListener("flutterInAppWebViewPlatformReady", setFlutterWebViewReady);
    return () => {
      window.removeEventListener('flutterInAppWebViewPlatformReady', setFlutterWebViewReady);
    };
  }, [])
  return {getToken, isFlutterInAppWebViewReady}
}
