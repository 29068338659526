import { DATE_ISO_8601_FORMAT } from '@monorepo/config';
import { formatDateTime } from '@monorepo/utils';

const notificationStatus = {
  READ: true,
  UNREAD: false,
};

export const convertNotificationList = (data) =>
  data?.map((item) => ({
    ...item,
    isRead: notificationStatus[`${item?.status}`],
    createdAt: formatDateTime(item?.createdAt),
  }));
