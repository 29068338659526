import { PageHeader } from '@monorepo/shared-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImgAppShipper from '../../../assets/images/app-shipper.png';
import ImgAppStock from '../../../assets/images/app-stock.png';
import ApplicationList from './components/ApplicationList';

const List = () => {
  const { t } = useTranslation();

  const applications = [
    { image: ImgAppShipper, title: 'HAC Shipper', description: 'Giao vận' },
    { image: ImgAppStock, title: 'HAC Stock', description: 'Kho' },
  ];
  return (
    <>
      <PageHeader pageTitle={t('application.pageTitle')} />
      <ApplicationList applications={applications} />
    </>
  );
};

export default List;
