/* eslint-disable react-hooks/exhaustive-deps */
import { isArray } from "@apollo/client/cache/inmemory/helpers";
import { SHIPPER_STATUS, STATUS_ORDER } from "@monorepo/config";
import { useGetCountOrderWithFilters, useShipperAppPermissions } from "@monorepo/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ALL_PARAMS_ORDER, ORDER_SHIPPING_TYPE } from "./constant";

const useOrder = () => {
  const { t } = useTranslation();
  const { canAssignShipper, loading } = useShipperAppPermissions();
  const [statusTab, setStatusTab] = useState([]);
  const [allParams, setAllParams] = useState([]);
  const renderTotalStatus = (valueStatus) => (valueStatus !== undefined ? `(${valueStatus})` : "");
  const { data, refetch: refetchCountOrder } = useGetCountOrderWithFilters();

  const orderStatus = ({ statuses, shippingStatus }) => {
    if (statuses === STATUS_ORDER.WAITING_FOR_SHIPPING && shippingStatus === null) {
      return "v1waitingForShippingNotSet";
    }
    // if (statuses === STATUS_ORDER.WAITING_FOR_SHIPPING && shippingStatus === "NOT_SET") {
    //   return "v2waitingForShippingNotSet";
    // }
    // if (statuses === STATUS_ORDER.DELIVERING && shippingStatus === "WAITING") {
    //   return "v1waitingForShippingWaiting";
    // }
    // if (statuses === STATUS_ORDER.WAITING_FOR_SHIPPING && shippingStatus === "WAITING") {
    //   return "v2waitingForShippingWaiting";
    // }
    if (
      isArray(statuses) &&
      statuses?.every((status) => ["WAITING_FOR_SHIPPING", "DELIVERING"]?.includes(status)) &&
      shippingStatus === "WAITING"
    ) {
      return "allVerWaitingForShippingWaiting";
    }
    if (statuses === STATUS_ORDER.DELIVERING && shippingStatus === "CONFIRM_PICKED") {
      return "v1deliveryConfirmPicked";
    }
    // if (statuses === STATUS_ORDER.DELIVERING && shippingStatus === null) {
    //   return "v2deliveryConfirmPicked";
    // }
    if (statuses === STATUS_ORDER.COMPLETED && shippingStatus === null) {
      return "completed";
    }
  };

  const convertOrderShippingType = (shippingType) => {
    switch (shippingType) {
      case ORDER_SHIPPING_TYPE.BUS:
        return t("order.orderList.bus");
      case ORDER_SHIPPING_TYPE.DELIVER_3PARTY:
        return t("order.orderList.deliver3Party");
      case ORDER_SHIPPING_TYPE.PICK_UP_COUNTER:
        return t("order.orderList.pickUpCounter");
      case ORDER_SHIPPING_TYPE.URBAN_COD:
        return t("order.orderList.urbanCOD");
      // case ORDER_SHIPPING_TYPE.URBAN_NOT_COD:
      //   return t('order.orderStatus.completed');
      default:
        return "";
    }
  };

  useEffect(() => {
    if (!loading) {
      const filterTabByPermission = canAssignShipper
        ? [
            {
              key: "v1waitingForShippingNotSet",
              label: `${t("order.orderStatus.waitingForDispatch")} ${renderTotalStatus(
                data?.v1waitingForShippingNotSet
              )}`,
              value: {
                statuses: STATUS_ORDER.WAITING_FOR_SHIPPING,
                shippingStatus: null,
              },
            },
            {
              key: "allVerWaitingForShippingWaiting",
              label: `${t("order.orderStatus.waitingForShipping")} ${renderTotalStatus(
                data?.allVerWaitingForShippingWaiting
              )}`,
              value: {
                statuses: [STATUS_ORDER.WAITING_FOR_SHIPPING, STATUS_ORDER.DELIVERING],
                shippingStatus: SHIPPER_STATUS.WAITING,
              },
            },
            {
              key: "v1deliveryConfirmPicked",
              label: `${t("order.orderStatus.delivering")} ${renderTotalStatus(data?.v1deliveryConfirmPicked)}`,
              value: {
                statuses: STATUS_ORDER.DELIVERING,
                shippingStatus: SHIPPER_STATUS.CONFIRM_PICKED,
              },
            },
            {
              key: "completed",
              label: `${t("order.orderStatus.completed")} ${renderTotalStatus(data?.completed)}`,
              value: {
                statuses: STATUS_ORDER.COMPLETED,
              },
            },
          ]
        : [
            {
              key: "allVerWaitingForShippingWaiting",
              label: `${t("order.orderStatus.waitingForShipping")} ${renderTotalStatus(
                data?.allVerWaitingForShippingWaiting
              )}`,
              value: {
                statuses: [STATUS_ORDER.WAITING_FOR_SHIPPING, STATUS_ORDER.DELIVERING],
                shippingStatus: SHIPPER_STATUS.WAITING,
              },
            },
            {
              key: "v1deliveryConfirmPicked",
              label: `${t("order.orderStatus.delivering")} ${renderTotalStatus(data?.v1deliveryConfirmPicked)}`,
              value: {
                statuses: STATUS_ORDER.DELIVERING,
                shippingStatus: SHIPPER_STATUS.CONFIRM_PICKED,
              },
            },
            {
              key: "completed",
              label: `${t("order.orderStatus.completed")} ${renderTotalStatus(data?.completed)}`,
              value: {
                statuses: STATUS_ORDER.COMPLETED,
              },
            },
          ];
      setStatusTab([...filterTabByPermission]);
      const filterParamsByPermission = canAssignShipper
        ? [...ALL_PARAMS_ORDER]
        : [
            ...ALL_PARAMS_ORDER.filter(
              (item) => !(item?.statuses === "WAITING_FOR_SHIPPING" && item.shippingStatus === null)
            ),
          ];
      setAllParams([...filterParamsByPermission]);
    }
  }, [canAssignShipper, loading, JSON.stringify(data)]);

  // const convertSt
  return {
    convertOrderShippingType,
    orderStatus,
    statusTab,
    refetchCountOrder,
    allParams,
  };
};

export default useOrder;
