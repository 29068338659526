import NotificationMobile from './List';

export const routerNotificationMobile = [
  {
    key: 'notification',
    path: '/notification',
    component: NotificationMobile,
    exact: true,
  },
  // {
  //   key: 'user/:id',
  //   path: '/user/:id',
  //   component: InfoUser,
  //   exact: true,
  // },
];

export default routerNotificationMobile;
