import React, { useState, useEffect } from 'react';
import { Button, Upload } from 'antd';
import { ACCEPT_FILE_TYPES, ACCEPT_IMG_TYPES } from '@monorepo/config';
import { dummyRequest, notify } from '@monorepo/utils';
import { useUploadDocument } from '@monorepo/hooks';
import { Spinner } from '@monorepo/shared-components';
import { Icon } from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import './index.scss';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const removeItemInArray = (array, index) => [
  ...array.slice(0, index),
  ...array.slice(index + 1, array.length),
];

const validateFileType = (type, accept) => (accept.includes(type) ? true : false);

export function UploadDocument(props) {
  const { onUploadSuccess, switchClear, multiple, accept, listType, ...rest } = props;

  const { handleUpload, loading } = useUploadDocument();
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const { t } = useTranslation();

  function beforeUpload(file) {
    const { size, type } = file;
    if (validateFileType(type, accept)) {
      //if file type image no check size else check size
      if (checkImage(file)) {
        return true;
      } else {
        const isLt5M = size / 1024 / 1024 <= 5;
        if (!isLt5M) {
          notify.error({
            message: t('uploadDocument.limitErrorMessage'),
            description: file?.name,
          });
        }
        return isLt5M;
      }
    } else {
      notify.error({
        message: t('uploadDocument.wrongFormat'),
        description: file?.name,
      });
      return false;
    }
  }

  function handleChange({ _, file }) {
    if (file.status === 'done') {
      getBase64(file.originFileObj, async (preview) => {
        setFiles([...files, file]);
        setPreviews([...previews, { previewFile: preview, previewsTitle: file.name }]);
      });
    }
  }

  function handleRemoveFile(index, e) {
    e.stopPropagation();
    setFiles(removeItemInArray(files, index));
    setPreviews(removeItemInArray(previews, index));
  }

  function checkImage(file) {
    if (ACCEPT_IMG_TYPES?.includes(file.type)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    onUploadSuccess([...files]);
  }, [files]);

  return (
    <div className="upload-container">
      <Spinner loading={loading} />
      <Upload
        accept={accept || ACCEPT_FILE_TYPES.join(',')}
        multiple={multiple || true}
        onChange={handleChange}
        listType={listType || 'picture'}
        className="upload-list-inline"
        customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
        beforeUpload={beforeUpload}
        onClick={(e) => e.stopPropagation()}
        {...rest}
      >
        <Button className="upload-button">+</Button>
      </Upload>

      <div className="upload-list">
        {previews?.map((preview, index) => (
          <div className="upload-item" key={index}>
            <div className="right-col" onClick={(e) => e.stopPropagation()}>
              {checkImage(files[index]) ? (
                <img className="file-thumbnail" src={preview?.previewFile} />
              ) : (
                <Icon.CustomIcon className="file-icon" icon={Icon.faFile} />
              )}

              <span className="file-name">{preview?.previewsTitle}</span>
            </div>
            <Icon.CustomIcon
              onClick={(e) => handleRemoveFile(index, e)}
              className="remove-icon"
              icon={Icon.faTrash}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
export default UploadDocument;
