import { FormInput, PageHeader } from '@monorepo/shared-components';
import { Avatar, Button, Form, notification, Popconfirm } from 'antd';
import './index.scss';
import React, { useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetMe, useDeleteUser, useLogout } from '@monorepo/hooks';

const InfoUser = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { me } = useGetMe();

  const options = me?.roles?.map(
    (item) => ({ label: item?.name, value: item?.id }));

  const initForm = {
    fullname: me?.fullname,
    roles: options?.map((item) => item?.value),
    departmentName: me?.department?.name,
    email: me?.email,
    telephone: me?.telephone,
  };

  const { deleteUser, loading } = useDeleteUser();
  const { logout } = useLogout();

  useEffect(() => {
    form.setFieldsValue(initForm);
  }, [initForm]);

  async function handleDeleteUser() {
    try {
      await deleteUser(me?.id);
      notification.success({
        message: t('profileMobile.deleteUserSuccess'),
      });
      await logout();
    } catch (e) {
      console.log(e);
      notification.error({
        message: t('profileMobile.deleteUserFailed'),
      });
    }

  }

  return (
    <>
      <PageHeader
        pageTitle={
          <>
            <ArrowLeftOutlined onClick={() => history.push('/user')} />
            {t('profileMobile.infoUser')}
          </>
        }
      />
      <div className='form-controls'>
        <Avatar size={110} src={me?.avatarURL} alt='Dog' />
        <Form form={form}>
          <FormInput
            formItemOptions={{
              label: <b>{t('profileMobile.fullName')}</b>,
              name: 'fullname',
            }}
            inputOptions={{ disabled: true, mode: 'multiple' }}
          />
          <FormInput
            type='SELECT'
            formItemOptions={{
              label: <b>{t('profileMobile.role')}</b>,
              name: 'roles',
            }}
            inputOptions={{
              disabled: true,
              options: options,
              mode: 'multiple',
            }}
          />
          <FormInput
            formItemOptions={{
              label: <b>{t('profileMobile.room')}</b>,
              name: 'departmentName',
            }}
            inputOptions={{ disabled: true }}
          />
          <FormInput
            formItemOptions={{
              label: <b>{t('profileMobile.email')}</b>,
              name: 'email',
            }}
            inputOptions={{ disabled: true }}
          />
          <FormInput
            formItemOptions={{
              label: <b>{t('profileMobile.phoneNumber')}</b>,
              name: 'telephone',
            }}
            inputOptions={{ disabled: true }}
          />
        </Form>
        <Popconfirm
          title={t('profileMobile.deleteMeConfirm')}
          okText={t('common.yes')}
          cancelText={t('common.no')}
          onConfirm={() => {
            handleDeleteUser();
          }}
        >
          <Button loading={loading} type='danger'>
            {t('profileMobile.deleteMe')}
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};

export default InfoUser;
