import { SHIPPER_STATUS, STATUS_ORDER } from "@monorepo/config";

export const PRIORITY_OPTIONS = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const CUSTOMER_PAYMENT_OPTIONS = {
  COD: "COD",
  CASH: "CASH",
};

export const ORDER_TYPE_OPTIONS = {
  SELLER: "SELLER",
  CUSTOMER: "CUSTOMER",
};

export const ORDER_STATUS = {
  WAITING_FOR_SHIPPING: "WAITING_FOR_SHIPPING",
  DELIVERING: "DELIVERING",
  COMPLETED: "COMPLETED",
};

export const ORDER_SHIPPING_TYPE = {
  BUS: "BUS",
  DELIVER_3PARTY: "DELIVER_3PARTY",
  PICK_UP_COUNTER: "PICK_UP_COUNTER",
  URBAN_COD: "URBAN_COD",
  URBAN_NOT_COD: "URBAN_NOT_COD",
};

export const ORDER_STATUS_TABS = [
  {
    key: "v1waitingForShippingNotSet",
    value: {
      statuses: STATUS_ORDER.WAITING_FOR_SHIPPING,
      shippingStatus: null,
    },
  },
  // {
  //   key: "v2waitingForShippingNotSet",
  //   value: {
  //     statuses: STATUS_ORDER.WAITING_FOR_SHIPPING,
  //     shippingStatus: SHIPPER_STATUS.NOT_SET,
  //   },
  // },
  // {
  //   key: "v1waitingForShippingWaiting",
  //   value: {
  //     statuses: STATUS_ORDER.DELIVERING,
  //     shippingStatus: SHIPPER_STATUS.WAITING,
  //   },
  // },
  // {
  //   key: "v2waitingForShippingWaiting",
  //   value: {
  //     statuses: STATUS_ORDER.WAITING_FOR_SHIPPING,
  //     shippingStatus: SHIPPER_STATUS.WAITING,
  //   },
  // },
  {
    key: "allVerWaitingForShippingWaiting",
    value: {
      statuses: [STATUS_ORDER.WAITING_FOR_SHIPPING, STATUS_ORDER.DELIVERING],
      shippingStatus: SHIPPER_STATUS.WAITING,
    },
  },
  {
    key: "v1deliveryConfirmPicked",
    value: {
      statuses: STATUS_ORDER.DELIVERING,
      shippingStatus: SHIPPER_STATUS.CONFIRM_PICKED,
    },
  },
  // {
  //   key: "v2deliveryConfirmPicked",
  //   value: {
  //     statuses: STATUS_ORDER.DELIVERING,
  //     shippingStatus: null,
  //   },
  // },
  {
    key: "completed",
    value: {
      statuses: STATUS_ORDER.COMPLETED,
      shippingStatus: null,
    },
  },
];

export const ALL_PARAMS_ORDER = [
  {
    key: "v1waitingForShippingNotSet",
    offset: 0,
    limit: 10,
    statuses: "WAITING_FOR_SHIPPING",
    shippingStatus: null,
    query: "",
  },
  // {
  //   key: "v2waitingForShippingNotSet",
  //   offset: 0,
  //   limit: 10,
  //   statuses: "WAITING_FOR_SHIPPING",
  //   shippingStatus: "NOT_SET",
  //   query: "",
  // },
  // {
  //   key: "v1waitingForShippingWaiting",
  //   offset: 0,
  //   limit: 10,
  //   statuses: "DELIVERING",
  //   shippingStatus: "WAITING",
  //   query: "",
  // },
  // {
  //   key: "v2waitingForShippingWaiting",
  //   offset: 0,
  //   limit: 10,
  //   statuses: "WAITING_FOR_SHIPPING",
  //   shippingStatus: "WAITING",
  //   query: "",
  // },
  {
    key: "allVerWaitingForShippingWaiting",
    offset: 0,
    limit: 10,
    statuses: ["WAITING_FOR_SHIPPING", "DELIVERING"],
    shippingStatus: "WAITING",
    query: "",
  },
  {
    key: "v1deliveryConfirmPicked",
    offset: 0,
    limit: 10,
    statuses: "DELIVERING",
    shippingStatus: "CONFIRM_PICKED",
    query: "",
  },
  // {
  //   key: "v2deliveryConfirmPicked",
  //   offset: 0,
  //   limit: 10,
  //   statuses: "DELIVERING",
  //   shippingStatus: null,
  //   query: "",
  // },
  {
    key: "completed",
    offset: 0,
    limit: 10,
    statuses: "COMPLETED",
    shippingStatus: null,
    query: "",
  },
];

export const CARRIER_METHOD = {
  INSTANT: "INSTANT",
  STANDARD: "STANDARD",
  ECONOMY: "ECONOMY",
};
