import React from 'react';
import './index.scss';

const ApplicationList = ({ applications }) => {
  return (
    <div className="application-list">
      {applications?.map((item) => (
        <div className="application-item">
          <img src={item.image} alt={item.name} />
          <b>{item.title}</b>
          <span>{item.description}</span>
        </div>
      ))}
    </div>
  );
};

export default ApplicationList;
