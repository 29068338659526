export const USER_ME_PERMISSION = [
  'TRANSACTION_ALL',
  'ORDER_ALL',
  'SELLER_ALL',
  'SELLER_VIEW',
  'SELLER_DEACTIVE',
  'SELLER_CREATE',
  'SELLER_UPDATE',
  'SELLER_CONTACT_VIEW',
  'SELLER_CONTACT_UPDATE',
  'SELLER_CONTACT_DELETE',
  'SYSTEM_ALL',
  'STAFF_ALL',
  'ROLE_ALL',
  'PRODUCT_ALL',
  'PRODUCT_VIEW',
  'PRODUCT_UPDATE',
  'PRODUCT_UPDATE_PRICE',
  'PRODUCT_VIEW_STOCK',
  'ACCOUNTANT_ALL',
  'ACCOUNTANT_BANK',
  'WAREHOUSE_ACCOUNTANT',
  'PRODUCT_ALL',
  'PRODUCT_VIEW',
  'PRODUCT_UPDATE',
  'PRODUCT_UPDATE_PRICE',
  'PRODUCT_VIEW_STOCK',
  'ORDER_VIEW',
  'ORDER_ALL',
  'ORDER_CREATE',
  'ORDER_APPROVE_STOCK',
  'ORDER_CONFIRM_PAYMENT',
  'SELLER_VIEW',
  'SELLER_DEACTIVE',
  'SELLER_CREATE',
  'SELLER_UPDATE',
  'SELLER_CONTACT_VIEW',
  'SELLER_CONTACT_UPDATE',
  'SELLER_CONTACT_DELETE',
  'STAFF_ALL',
  'STAFF_CREATE',
  'STAFF_UPDATE',
  'ROLE_ALL',
  'ROLE_VIEW',
  'ROLE_CREATE',
  'ROLE_UPDATE',
  'ROLE_DELETE',
  'PRODUCT_VIEW',
  'PRODUCT_UPDATE',
  'PRODUCT_UPDATE_PRICE',
  'PRODUCT_VIEW_STOCK',
  'ACCOUNTANT_BANK',
  'WAREHOUSE_ACCOUNTANT',
  'STAFF_ASSIGN_ROLE',
  'STAFF_VIEW',
  'STAFF_DEACTIVE',
  'DEPARTMENT_ALL',
  'ORDER_WAIT_STOCK',
  'ORDER_DENY_STOCK',
  'ORDER_EXPORT_STOCK',
  'ORDER_ASSIGN_SHIPPER',
  'ORDER_COMPLETE',
  'ORDER_CANCEL',
  'ORDER_RECREATE_CART',
  'ACCOUNTANT_VIEW_SELLER_LIST',
  'ACCOUNTANT_VIEW_ACCOUNTANT_LIST',
  'ACCOUNTANT_VIEW_SELLER_TX_HISTORY',
  'ACCOUNTANT_VIEW_ACCOUNTANT_TX_HISTORY',
  'ACCOUNTANT_RECHARGE_WAREHOUSE_ACCOUNTANT',
  'ORDER_COMPLETE_ASSEMBLY',
  'ORDER_VIEW',
  'ORDER_ALL',
  'ORDER_CREATE',
  'ORDER_APPROVE_STOCK',
  'ORDER_CONFIRM_PAYMENT',
  'SELLER_VIEW',
  'SELLER_DEACTIVE',
  'SELLER_CREATE',
  'SELLER_UPDATE',
  'SELLER_CONTACT_VIEW',
  'SELLER_CONTACT_UPDATE',
  'SELLER_CONTACT_DELETE',
  'STAFF_ALL',
  'STAFF_CREATE',
  'STAFF_UPDATE',
  'ROLE_ALL',
  'ROLE_VIEW',
  'ROLE_CREATE',
  'ROLE_UPDATE',
  'ROLE_DELETE',
  'PRODUCT_VIEW',
  'PRODUCT_UPDATE',
  'PRODUCT_UPDATE_PRICE',
  'PRODUCT_VIEW_STOCK',
  'ACCOUNTANT_BANK',
  'WAREHOUSE_ACCOUNTANT',
  'STAFF_ASSIGN_ROLE',
  'STAFF_VIEW',
  'STAFF_DEACTIVE',
  'DEPARTMENT_ALL',
  'DEPARTMENT_VIEW',
  'DEPARTMENT_CREATE',
  'DEPARTMENT_UPDATE',
  'DEPARTMENT_DELETE',
  'ORDER_WAIT_STOCK',
  'ORDER_DENY_STOCK',
  'ORDER_EXPORT_STOCK',
  'ORDER_ASSIGN_SHIPPER',
  'ORDER_COMPLETE',
  'ORDER_CANCEL',
  'ORDER_RECREATE_CART',
  'ACCOUNTANT_VIEW_SELLER_LIST',
  'ACCOUNTANT_VIEW_ACCOUNTANT_LIST',
  'ACCOUNTANT_VIEW_SELLER_TX_HISTORY',
  'ACCOUNTANT_VIEW_ACCOUNTANT_TX_HISTORY',
  'ACCOUNTANT_RECHARGE_WAREHOUSE_ACCOUNTANT',
  'ORDER_COMPLETE_ASSEMBLY',
  'ORDER_SHIPPER_DELIVER',
];

export const PERMISSION_ARRAY = [...new Set(USER_ME_PERMISSION)];
