import { Collapse, Input, Space } from 'antd';
import { Swiper } from 'antd-mobile';
import { forwardRef, useEffect, useState } from 'react';
import CustomPullToRefresh from '../custom-pull-to-refresh';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce, useWindowSize } from '@monorepo/hooks';
import { pick, omit } from 'lodash';
import { t } from 'i18next';
const { Panel } = Collapse;

const Order = ({
  order,
  orderStatus,
  OrderHeader,
  fieldRenderHeader,
  OrderBody,
  fieldRenderBody,
  refetch,
}) => {
  const orderHeader = pick(order, [...fieldRenderHeader]);

  const orderBody = pick(order, [...fieldRenderBody]);

  return (
    <Collapse collapsible="header">
      <Panel
        header={
          <OrderHeader orderHeader={orderHeader} orderStatus={orderStatus} refetch={refetch} />
        }
        key="1"
      >
        <OrderBody orderBody={orderBody} />
      </Panel>
    </Collapse>
  );
};

const Orders = ({
  params,
  useGetOrders,
  handleOrderStatus,
  OrderHeader,
  fieldRenderHeader,
  OrderBody,
  fieldRenderBody,
  refetchCountOrder,
  indexStatusBar,
  tabKey,
  currentActiveTabKey,
  isIncludeOrderItems,
}) => {
  const checkCurrentActiveTabKeyInTabKey = tabKey === currentActiveTabKey;
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedValue = useDebounce(searchTerm, 500);
  const { height } = useWindowSize();
  const [heightScrollAble, setHeightScrollAble] = useState(1);
  const [getParams, setGetParams] = useState({
    pagination: { ...pick(params, ['limit', 'offset']) },
    filters: {
      ...omit(params, ['limit', 'offset', 'key']),
    },
    skipQuery: !checkCurrentActiveTabKeyInTabKey,
    withOrderItems: isIncludeOrderItems,
  });

  const { data, loading, fetchMore, paginationData, refetch } = useGetOrders(getParams);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setOrders(data);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const calcHeightScrollAble = height - 252;
    setHeightScrollAble(calcHeightScrollAble);
  }, [height]);

  useEffect(() => {
    if (!loading) {
      const newParams = {
        ...getParams,
        pagination: { offset: 0, limit: 10 },
        filters: {
          ...getParams.filters,
          query: debouncedValue,
        },
      };

      setGetParams(newParams);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (checkCurrentActiveTabKeyInTabKey) {
      handleRefresh();
    }
  }, [currentActiveTabKey]);

  const handleRefresh = () =>
    refetch().then((res) => {
      setOrders([...res?.data?.order?.pagination?.orders]);
    });

  const fetchMoreData = async () => {
    const currentLength = orders.length;
    await fetchMore({
      variables: {
        ...params,
        pagination: {
          offset: currentLength,
          limit: 10,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newOrders = fetchMoreResult.order.pagination.orders;
        setOrders([...orders, ...newOrders]);
      },
    });
  };

  return (
    <div className="orders">
      <div className="filter-box">
        <div className="search-input">
          <Input
            className="custom-input"
            // prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t('order.orderList.placeholderInputSearch')}
            allowClear={true}
            defaultValue={params?.query}
            // value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
      </div>
      <CustomPullToRefresh
        onRefresh={() => {
          refetch().then((res) => {
            setOrders([...res?.data?.order?.pagination?.orders]);
            refetchCountOrder();
          });
        }}
      >
        <Space direction="vertical">
          <InfiniteScroll
            next={fetchMoreData}
            dataLength={orders.length}
            hasMore={paginationData?.total === orders.length ? false : true}
            height={heightScrollAble}
          >
            {orders?.map((order, index) => (
              <Order
                key={index}
                order={order}
                orderStatus={handleOrderStatus({ ...getParams?.filters })}
                OrderHeader={OrderHeader}
                fieldRenderHeader={fieldRenderHeader}
                OrderBody={OrderBody}
                fieldRenderBody={fieldRenderBody}
                refetch={handleRefresh}
              />
            ))}
          </InfiniteScroll>
        </Space>
      </CustomPullToRefresh>
    </div>
  );
};

export const SwiperMobile = forwardRef(
  (
    {
      onIndexChange,
      defaultIndex,
      allParams,
      useGetOrders,
      handleOrderStatus,
      OrderHeader,
      fieldRenderHeader,
      OrderBody,
      fieldRenderBody,
      refetchCountOrder,
      currentActiveTabKey,
      isIncludeOrderItems,
    },
    ref
  ) => {
    const renderSwiperItem = allParams?.map((item, index) => (
      <Swiper.Item key={index}>
        <Orders
          params={{
            ...item,
            // query: debouncedValue,
          }}
          useGetOrders={useGetOrders}
          handleOrderStatus={handleOrderStatus}
          OrderHeader={OrderHeader}
          fieldRenderHeader={fieldRenderHeader}
          OrderBody={OrderBody}
          fieldRenderBody={fieldRenderBody}
          refetchCountOrder={refetchCountOrder}
          indexStatusBar={defaultIndex}
          tabKey={item?.key}
          currentActiveTabKey={currentActiveTabKey}
          isIncludeOrderItems={isIncludeOrderItems}
        />
      </Swiper.Item>
    ));

    return (
      <Swiper
        ref={ref}
        onIndexChange={onIndexChange}
        defaultIndex={defaultIndex || 0}
        indicator={() => null}
      >
        {renderSwiperItem}
      </Swiper>
    );
  }
);

export default SwiperMobile;
