/* eslint-disable react/jsx-no-useless-fragment */
import { PageHeader, Spinner, StatusTabs } from "@monorepo/shared-components";
import { useEffect, useRef, useState } from "react";

import { useGetUserPermissions, useOrderPermissions, useShipperAppPermissions } from "@monorepo/hooks";
import { buildQueryString, getQuery } from "@monorepo/utils";
import { findIndex } from "lodash";
import { useTranslation } from "react-i18next";
import Page403 from "~/pages/PageError/403";
import { ORDER_STATUS_TABS } from "../constant";
import useOrder from "../hooks";
import ListOrder from "./components/ListOrder";

const OrderList = () => {
  const { t } = useTranslation();
  const { loading: loadUserPermissions } = useGetUserPermissions();
  const { canViewOrder } = useOrderPermissions();
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [currentActiveTabKey, setCurrentActiveTabKey] = useState(null);
  const swiperRef = useRef(null);
  const GET_QUERY = getQuery();
  const { orderStatus, statusTab, refetchCountOrder } = useOrder();
  const { canAssignShipper } = useShipperAppPermissions();

  const getParams = (value) => ({
    offset: 0,
    limit: 10,
    statuses: value.statuses,
    shippingStatus: value.shippingStatus,
    query: "",
  });

  const handleChange = (value) => {
    const query = getParams(value);
    // setParams({ ...query });
    buildQueryString({
      params: { ...query },
    });
  };

  useEffect(() => {
    const getStatusTabs = canAssignShipper
      ? statusTab
      : statusTab.filter((item) => item.key !== "WAITING_FOR_DISPATCH");

    const currentTab = getStatusTabs[defaultIndex];
    setCurrentActiveTabKey(currentTab?.key);
  }, [defaultIndex, statusTab]);

  const handleChangeActiveIndex = (index) => {
    const statusValue = ORDER_STATUS_TABS[index]?.value;
    setDefaultIndex(index);
    const query = getParams(statusValue);
    buildQueryString({
      params: { ...query },
    });
  };
  const getStatusActive = orderStatus({ ...statusTab[defaultIndex]?.value });

  useEffect(() => {
    const defaultStatus = orderStatus({
      statuses: GET_QUERY.statuses,
      shippingStatus: GET_QUERY.shippingStatus,
    });
    const findDefaultIndex = findIndex(ORDER_STATUS_TABS, { key: defaultStatus });
    setDefaultIndex(findDefaultIndex < 0 ? 0 : findDefaultIndex);
  }, []);

  return (
    <>
      {loadUserPermissions ? (
        <Spinner loading={loadUserPermissions} />
      ) : canViewOrder ? (
        <>
          <PageHeader pageTitle={t("order.orderList.pageTitle")} />
          <StatusTabs onChange={handleChange} statusActive={getStatusActive} statusTab={statusTab} ref={swiperRef} />
          <ListOrder
            handleChangeActiveIndex={handleChangeActiveIndex}
            defaultIndex={defaultIndex}
            currentActiveTabKey={currentActiveTabKey}
            ref={swiperRef}
            handleOrderStatus={orderStatus}
            refetchCountOrder={refetchCountOrder}
          />
        </>
      ) : (
        <Page403 />
      )}
    </>
  );
};

export default OrderList;
